import { Flexbox, Grid } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'
import PageTitle from '@src/components/PageTitle'
import PageText from '@src/components/PageText'

const S03About2 = (props: FlexboxTypes.Props) => {
  return (
    <Flexbox column {...props}>
      <PageTitle label="" title="Что мы решаем?" />
      <Grid
        gap={['4rem', '2rem']}
        templateColumns={['1fr 1fr', '1fr']}
        style={{
          ul: {
            paddingLeft: '1rem',
            fontWeight: 400,
            lineHeight: 2,
          },
        }}
      >
        <PageText>
          <ul>
            <li>Неточную запись веса сборщиком (потери, большие списания)</li>
            <li>Ошибки заполнения заказа менеджером</li>
            <li>Ошибки заполнения веса сборщиком</li>
            <li>Ошибки заполнения веса оператором</li>
            <li>Длительный перенос данных со сборочных весов</li>
            <li>Длительный прием заказа</li>
            <li>
              Частые спорные моменты в заказах (приложение клиента, комменты к
              позиции/заказу)
            </li>
            <li>Недокомплектацию заказа сборщиками</li>
            <li>Перепутанные позиции сборщиками</li>
            <li>Излишний недовес/перевес сборщиками</li>
            <li>Низкую скорость работы сборщиков</li>
            <li>Ошибки при вычитании тары (апельсины в коробке на весах)</li>
            <li>Неудобную систему паллетов</li>
            <li>Позднее формирование списка докупок</li>
            <li>Сложное обновление списка докупок в онлайн режиме (добавить/убавить)</li>
            <li>Неудобную коммуникацию с докупщиком (есть/нету)</li>
            <li>Игнорирование комментариев к позиции</li>
            <li>Игнорирование комментариев к заказу</li>
            <li>
              Отсутствие контроля прогресса сборки (сколько позиций собрано/осталось)
            </li>
            <li>Отсутствие статистики КПД сотрудников</li>
            <li>Отсутствие статистики по ошибкам</li>
            <li>Невозможность отслеживания товара (когда, кем, как)</li>
          </ul>
        </PageText>
        <PageText>
          <ul>
            <li>Отсутствие контроля отгруженного товара</li>
            <li>Путаницу позиций при погрузке</li>
            <li>Путаницу позиций при транспортировке</li>
            <li>Сложность проверки при погрузке</li>
            <li>Отсутствие оперативной связи со складом (внутр чат, алерты)</li>
            <li>Отсутствие онлайн-контроля заказов</li>
            <li>Длительное назначение водителей на текущие заказы</li>
            <li>Отсутствие контроля местоположения водителей (какие заказы сданы)</li>
            <li>Невозможность корректировки заказа во время сборки</li>
            <li>Неудобную работу со стикерами ЕАС</li>
            <li>Отсутствие онлайн-мониторинга сборщиков (новеньких)</li>
            <li>
              Отсутствие системы распределения заказов (водителям приходится уточнять)
            </li>
            <li>Отсутствие уведомлений об отклонениях в работе AI</li>
            <li>Недобросовестность работников</li>
            <li>Проблему довоза позиций (снижение рентабельности)</li>
            <li>Длительное формирование документов</li>
            <li>Длительную приемку товаров клиентами</li>
            <li>Отсутствие удобной аналитики AI</li>
            <li>Низкую квалификацию работников</li>
            <li>Дефицит кадров</li>
            <li>Отсутствие конкурентных преимуществ</li>
          </ul>
        </PageText>
      </Grid>
    </Flexbox>
  )
}

export default S03About2
